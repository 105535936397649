@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&family=Poppins:wght@100;400;600;700&display=swap');
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input {
  --tw-shadow: none !important;
}
html {
  scroll-behavior: smooth;
}

body .react-kanban-column {
	z-index: 3;
	background-color: #fff; /* Gray/Gray-100 */
	box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
	border-radius: 20px;
}
body.dark {
	background-color: #212529; /* Gray/Gray-100 */
	border-radius: 20px;
	box-shadow: none;
}


Button {
  margin-right: 20px;
}
hr {
  background: 'lime';
  color: 'lime';
  height: '3px';
}

p {
  margin-bottom: 0 !important;
}

.my-modal {
  background-color: #1e272e;
  opacity: 96%;
  /* background-color: #fff */
}
.modal.content {
  /* --bs-modal-bg: rgb(9 199 225) !important; */
}
.logout-button {
  margin-right: 10px;
  padding: 20px !important;
}

.sidemenu {
  width: 240px;
  border: 1px solid white;
}
.chatbox {
  width: 90%;
  color: whitesmoke;
}
.chat-input-holder {
  padding: 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1e272e;
}
.chat-input-textarea {
  background-color: #40414f;
  width: calc(90% - 300px);
  margin-left: 310px;
  border: none;
  border-radius: 5px;
  height: 50px;
  padding: 12px;
  color: white;
  /* margin: 12px; */
  /* outline: none !important; */
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
}

.chat-log {
  /* padding: 24px; */
  /* overflow-y: scroll; */
  text-align: left;

  margin-bottom: 100px;
}
.chat-message {
  /* margin-left: auto; */
  margin-right: auto;
  width: 90% !important;
  background-color: #1e272e;
  white-space: pre-line;
}
.chatgpt {
  background-color: #444654;
  /* color: #444654; */
}
.chat-message-center {
  max-width: 640px;

  padding: 12px;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  overflow-wrap: break-word;
}
.avatar {
  /* background: #fff; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.avatar.chatgpt {
  /* background: #299ea4; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.btn-btn {
  margin-top: 20px;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
  color: rgb(78, 78, 78) !important;
}
.btn-btn-form {
  /* margin-top: 20px; */
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
  color: rgb(78, 78, 78) !important;
  margin-right: 15px;
}
.message {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  width: 90%;
  font-size: 14px;
}
.rounded-circle {
  width: 110px;
}
.card-custom {
  background-color: rgb(9 199 225 / var(--tw-text-opacity)) !important;
}
.card-custom-main {
  background-color: rgb(37, 51, 58) !important;
}
.validation {
  color: rgb(67, 208, 255) !important;
}
.invalid-feedback-select {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.error-select {
  display: none;
}

/* Chat Widget CSS */
.rcw-conversation-container > .rcw-header {
}
.rcw-widget-container {
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: #35cce6; */
  /* width: 100%; */
}
.rcw-conversation-container {
  box-shadow: none !important;
  background-color: #fff !important;
}
.rcw-messages-container {
  /* -webkit-box-shadow: 0 0px 0px 0px; */
}
.rcw-launcher {
  /* -webkit-box-shadow: 0 0px 0px 0px; */
  box-shadow: none !important;
  background-color: transparent !important;
  /* margin: 3px !important;
    margin-left: 50px !important;
     */

  margin: 0px -9px 4px 0px !important;
}
.rcw-header {
  background-color: #2a3d40 !important;
  padding: 20px 0 10px !important;
}
.rcw-close-button {
  background-color: #2a3d40 !important;
}
.rcw-sender {
  /* height: 60px !important; */
  padding: 10px !important;
}
.rcw-new-message {
  /* background-color: #f4f7f9 !important; */
}
.rcw-avatar {
  margin-top: 9px;
}
.rcw-conversation-container .avatar {
  margin-right: auto !important;
  margin-left: auto;
  width: 100px !important;
  border-radius: 10px !important;
  background: #fff;
  padding: 10px;
}
.rcw-picker-icon {
  margin-bottom: 7px;
  height: 21px;
}
.rcw-send-icon {
  margin-bottom: 7px;
  margin-left: 15px;
}
.rcw-input {
  margin-left: 7px;
  overflow-y: hidden;
  padding: 10px 5px;
}
/* .rcw-message > .rcw-response {
  background-color: black;
  color: white;
} */
.cc-email-input {
  background-color: #1e272e;
  margin: 0 auto !important;
  height: 270px;
  border-radius: 20px;
  padding: 20px;
  width: 95%;
}
.cc-email-input input {
  border-radius: 5px;
}
.loader-dots {
  margin-bottom: 6px;
}
.rcw-new-message {
  margin-left: 10px;
  padding: 0px 0px !important;
}
@media screen and (max-width: 780px) {
  .chat-message {
    width: 90% !important;
  }
  .chat-input-textarea {
    width: 90%;
    margin-left: 5%;
  }
}
/* navigation notes */
@media screen and (max-width: 780px) {
  .chat-message {
    width: 90% !important;
  }
  .chat-input-textarea {
    width: 90%;
    margin-left: 5%;
  }
}
/* navigation notes */
.nav {
  margin-top: 20px;
  margin: auto;
  width: 30%;
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d !important;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
  font-size: 12px;
  text-transform: uppercase;
  padding: 17px 29px 16px;
  line-height: 1;
  background-color: #f7f7f7 !important;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.55);
  margin: 0.5rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e3ebf7 !important;
  color: #285192 !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

/* STRIPE CSSS */

/* src/App.css */
.pricing-table {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 20px;
  width: 280px;
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-card-header {
  text-align: center;
  margin-bottom: 20px;
}

.pricing-card-header h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #1e272e;
}

.pricing-card-header h4 {
  font-size: 32px;
  font-weight: bold;
  color: #1e272e;
}

.pricing-card-header h4 span {
  font-size: 16px;
  font-weight: normal;
  color: #1e272e;
}

.pricing-card-body {
  text-align: center;
  margin-bottom: 20px;
  color: #1e272e;
}

.pricing-card-body ul {
  list-style-type: none;
  padding: 0;
  color: #1e272e;
}

.pricing-card-body li {
  margin-bottom: 10px;
}

.pricing-card-footer {
  text-align: center;
}

.pricing-card-footer button {
  background-color: rgb(9 199 225);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pricing-card-footer button:hover {
  background-color: orange;
}

/* TRAIN CHAT CSS */
/* src/App.css */
.chat-container {
  display: flex;
  height: 80vh; /* Add this line */
}

.sidebarTrain {
  width: 300px;
  background-color: #1e272e; /* Update the background color */
  border-right: 1px solid #e0e0e0;
  padding: 20px;
  height: calc(
    100% - 20px
  ); /* Make the height 100% minus the 20px margin from the top */
  margin-top: 20px; /* Add the 20px margin from the top */
  box-sizing: border-box; /* Add this line to account for padding in the height calculation */

  transition: width 0.3s; /* Add this line for smooth transition */
  position: relative;
}

/* src/App.css */
/* Media query for screen size 850px or below */
@media screen and (max-width: 970px) {
  .collapse-toggle {
    /* display: block; */
    /* right: -30px; */
  }
  .chat-input-textarea {
    width: 90%;
    margin-left: 50px;
  }
  .chat-message {
    width: 100% !important;
  }
  .chatbox {
    width: 90%;
  }
}
.collapse-toggle {
  position: absolute;
  right: 0px;
  top: 0px;
  /* background-color: #fff; */
  color: #ffffff;
  /* border-left: 1px white solid; */
  /* border-bottom: 1px white solid; */
  /* border-top: 1px white solid; */
  width: 40px;
  height: 50px;
  font-size: 18px;
  /* display: none ; */
  z-index: 10; /* Add this line to ensure the button is on top of other elements */
}
.sidebarTrain h3 {
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center; /* Add this line to center the model header */
}
.sidebarTrain.collapsed {
  width: 50px; /* Update this value to your desired collapsed width */
}
.sidebarTrain ul {
  list-style-type: none;
  padding: 0;
  display: flex; /* Add this line to enable flexbox layout */
  flex-direction: column; /* Add this line to align the model names vertically */
  align-items: center; /* Add this line to center the model names horizontally */
}
.sidebarTrain li {
  margin-bottom: 10px;
  cursor: pointer;
  color: #ffffff; /* Add this line to change the color of the list items */
}

.sidebarTrain li:hover {
  text-decoration: underline;
}

.file-upload {
  border: 2px dashed #2196f3;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.file-upload p {
  margin: 0;
  font-weight: bold;
  color: #ffffff; /* Add this line to change the color of the file upload text */
}
